import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/SEO"

const Error = () => {
  return (
    <Layout>
      <SEO title="Error" description="Error happened" />
      <main className="error-page">
        <div className="error-container">
          <h1>Er...</h1>
          <h2>It doesnt mean it doesnt exist... It just means you are too early</h2>
          <h3>Head back to home page and lets start over!</h3>
          <Link to="/" className="btn">
            back home
          </Link>
        </div>
      </main>
    </Layout>
  )
}

export default Error